<template>
    <div class="withdrawal-record">
        <van-tabs v-model="activeName" class="mytabs" title-active-color="#4290FB" @click="gettabs" title-inactive-color="#303133" color="#4290FB"	>
            <van-tab :title='$t("myWallet.all")' name="1">
                <div class="nodata" v-if="recordList.length == 0">
                    <img src="@/assets/img/data-null.png" alt="">
                </div>
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh" :class="{'list-noData':recordList.length==0}" class="myrefresh" v-if="recordList.length>0" >
                    <van-list
                        v-model="loading"
                        :finished="finished"
                        :finished-text='$t("myWallet.nomore")'
                        @load="onLoad"
                    >
                        <!-- <van-cell/> -->
                        <div class="list-item"  v-for="(item,index) in recordList" :key="index" >
                            <div class="item-details">
                                <p class="price">{{$t("myWallet.wailetMoney")}}：+ {{item.money}}{{$t("comonTxt.money")}}</p>
                                <p class="time">{{$t("myWallet.wailetTime")}}：{{getLocalMinutesTime(item.withdrawDt)}}</p>
                            </div>
                            <i class="icon"> </i>
                        </div>
                    </van-list>
                </van-pull-refresh>
            </van-tab>
            <van-tab :title='$t("myWallet.Withdrawal")' name="2">
                <div class="nodata" v-if="recordList.length == 0">
                    <img src="@/assets/img/data-null.png" alt="">
                </div>
                <van-pull-refresh v-model="refreshing1" @refresh="onRefresh" class="myrefresh" :class="{'list-noData':recordList.length==0}" v-if="recordList.length>0">
                    <van-list
                        v-model="loading"
                        :finished="finished"
                        :finished-text='$t("myWallet.nomore")'
                        @load="onLoad"
                    >
                        <!-- <van-cell/> -->
                        <div class="list-item"  v-for="(item,index) in recordList" :key="index" >
                            <div class="item-details">
                                <p class="price">{{$t("myWallet.wailetMoney")}}：+ {{item.money}}{{$t("comonTxt.money")}}</p>
                                <p class="time">{{$t("myWallet.wailetTime")}}：{{getLocalMinutesTime(item.withdrawDt)}}</p>
                            </div>
                            <i class="icon"> </i>
                        </div>
                    </van-list>
                </van-pull-refresh>
            </van-tab>
            <van-tab :title='$t("myWallet.haveWithdrawal")' name="3">
                <div class="nodata" v-if="recordList.length == 0">
                    <img src="@/assets/img/data-null.png" alt="">
                </div>
                <van-pull-refresh v-model="refreshing2" @refresh="onRefresh" class="myrefresh" :class="{'list-noData':recordList.length==0}" v-if="recordList.length>0">
                    <van-list
                        v-model="loading"
                        :finished="finished"
                        :finished-text='$t("myWallet.nomore")'
                        @load="onLoad"
                        :immediate-check="false"
                    >
                        <!-- <van-cell/> -->
                        <div class="list-item"  v-for="(item,index) in recordList" :key="index" >
                            <div class="item-details">
                                <p class="price">{{$t("myWallet.wailetMoney")}}：+ {{item.money}}{{$t("comonTxt.money")}}</p>
                                <p class="time">{{$t("myWallet.wailetTime")}}：{{getLocalMinutesTime(item.withdrawDt)}}</p>
                            </div>
                            <i class="icon"> </i>
                        </div>
                    </van-list>
                </van-pull-refresh>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import { moneyQuery } from "@/api/shop";
import { getLocalMinutesTime } from "@/utils/date";

export default {
    data(){
        return{
            finished:false,
            loading:false,
            refreshing:false,
            refreshing1:false,
            refreshing2:false,
            activeName:"1",
            pageNum:1,
            pageSize:10,
            recordList:[],
            getLocalMinutesTime
        }
    },
    created(){
        document.title = this.$t("myWallet.wailetrecord");
        this.getWithdrawal();
    },
    methods:{
        async getWithdrawal(){
            let datadd = {};
            if(this.activeName==1){
                datadd.statusList = [130004,130005];
            }else if(this.activeName == 2){
                datadd.statusList = [130004];
            }else if(this.activeName == 3){
                datadd.statusList = [130005];
            }
            datadd.pageNum = this.pageNum;
            datadd.pageSize = this.pageSize;
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let result = await moneyQuery(datadd);
            toast.clear();
            if(result.data.errorCode == 0){
                if(this.pageNum == 1){
                    this.recordList = result.data.data.data;
                }else{
                    result.data.data.data.map(item=>{
                        this.recordList.push(item);
                    })
                }
                if(result.data.data.data.length<this.pageSize){
                    this.finished = true;
                }
            }else{
                this.recordList = []
            }
        },
        gettabs(){
            this.pageNum = 1;
            this.loading = false;
            this.finished = false;
            this.recordList = [];
            this.getWithdrawal();
        },
        async onRefresh(){
            this.recordList = [];
            this.pageNum = 1;
            await this.getWithdrawal();
            this.loading = false;
            this.finished = false;
            // this.refreshing = false;
            // console.log(this.recordList);
            this.$forceUpdate();
            // this.onLoad();
        },
        async onLoad(){
            if(!this.finished&&this.recordList.length>0){
                // console.log("lll");
                this.pageNum++;
                await this.getWithdrawal();
                this.loading = false;
            }
        }
    },

}
</script>

<style lang="scss" scoped>
    .withdrawal-record{
        height: 100%;
        padding: 0 .6rem;
        .mytabs{
            display: flex;
            flex-direction: column;
            height: 100%;
            .van-tabs__wrap{
                height: 2rem;
                flex: auto 0 0;
                border-bottom: .02rem solid #F5F6FA;
            }
            .van-tabs__content{
                flex: 1;
                overflow: hidden;
                .van-tab__pane{
                    overflow: auto;
                    height: 100%;
                    .nodata{
                        height: 96%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img{
                            width: 8rem;
                        }
                    }
                }
                .myrefresh{
                    min-height: 100%;
                    .van-loading{
                        margin-top: .7rem;
                    }
                }
                .list-noData{
                    .van-list__finished-text{
                        display: none;
                    }
                }
            }
            .list-item{
                padding: .56rem 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: .04rem solid #F5F6FA;
                &:nth-last-child(2){
                    border-bottom: 0;
                }
                .icon{
                    margin-left: .3rem;
                    width: .26rem;
                    height: .4rem;
                    background: url("~@/assets/img/more-icon.png") no-repeat;
                    background-size: 100% 100%;
                }
                .item-details{
                    .price{
                        font-size: .64rem;
                        font-weight: 500;
                        color: #303133;
                        line-height: .9rem;

                    }
                    .time{
                        font-size: .56rem;
                        font-weight: 400;
                        color: #838385;
                        line-height: .8rem;
                    }
                }
            }
        }
    }
</style>
